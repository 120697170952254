import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock } from "vue"

import GalleryModal from '@lmt-rpb/GalleryModal/GalleryModal.vue';
import { onMounted, onUnmounted, ref } from 'vue';
import { useGalleryListeners } from '../../composables/galleryListeners';
import type { GalleryElement } from '@/interfaces/components/galleryElement';


export default /*@__PURE__*/_defineComponent({
  __name: 'RegionGallery',
  setup(__props) {


const galleryOpen = ref(false);
const galleryItems = ref<Array<GalleryElement>>([]);
const galleryIndex = ref(0);

function initLightboxImages() {
	const elements: NodeListOf<HTMLLIElement> = document.querySelectorAll('[data-lightbox-content]');

	return Array.from(elements).map((element) => ({
			content: element.dataset.lightboxContent,
			preview: element.dataset.lightboxPreview ?? element.dataset.lightboxContent,
			copyright: element.dataset.lightboxCopyright ?? '',
			type: element.dataset.lightboxType ?? 'image',
		}));
}

function initAdditionalImages() {
	const mosaicData: HTMLElement | null = document.querySelector('.media-mosaic-data');
	if (!mosaicData?.dataset.mosaic) {
		mosaicData?.remove();
		return [];
	}
	const elements: {
		alt_text:string
		copyright?: string
		medium?: string
		original: string
		small: string
		title: string
	}[] = JSON.parse(mosaicData.dataset.mosaic);
	mosaicData?.remove();

	return Array.from(elements).map((element) => ({
			content: element.original,
			preview: element.small ?? element.original,
			copyright: element.copyright ?? '',
			type: 'image'
		}));
}

function initLightboxVideo() {
	const elements: NodeListOf<HTMLLIElement> = document.querySelectorAll('[data-lightbox-video-content]');

	return Array.from(elements).map((element) => ({
			content: element.dataset.lightboxVideoContent,
			preview: element.dataset.lightboxVideoPreview ?? element.dataset.lightboxVideoContent,
			copyright: element.dataset.lightboxVideoCopyright ?? '',
			type: element.dataset.lightboxVideoType ?? 'image',
		}));
}

function initGalleryImages() {
	galleryItems.value = [
		...initLightboxImages(),
		...initAdditionalImages(),
		...initLightboxVideo(),
	] as GalleryElement[];
}

const handleButtonClick = () => {
	galleryOpen.value = !galleryOpen.value;
};

const openGallery = (index: number) => {
	galleryIndex.value = index;
	galleryOpen.value = true;
};

const closeGallery = () => {
	galleryOpen.value = false;
};

useGalleryListeners('.media-mosaic__image', openGallery);

onMounted(() => {
	initGalleryImages();
	document.querySelector('.media-mosaic__button')?.addEventListener('click', handleButtonClick);
});

onUnmounted(() => {
	document.querySelector('.media-mosaic__button')?.removeEventListener('click', handleButtonClick);
});


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(GalleryModal, {
    modelValue: galleryOpen.value,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((galleryOpen).value = $event)),
    elements: galleryItems.value,
    index: galleryIndex.value,
    onClose: closeGallery
  }, null, 8 /* PROPS */, ["modelValue", "elements", "index"]))
}
}

})