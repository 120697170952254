import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "region-map" }
const _hoisted_2 = { class: "region-map__headline" }

import MapPreviewAndModal from '@lmt-rpb/MapPreviewAndModal/MapPreviewAndModal.vue';
import type { StaticMaps, MarkerData } from '@/interfaces/google-maps';
import { onMounted, ref, computed, watch } from 'vue';
import { useStore } from '@/components/common/store';
import type { ApiDataTypes } from '@/components/common/types';

interface Props {
	destinationName: string;
	staticMaps: StaticMaps;
	customMarkerData?: Array<MarkerData>;
	lat: number;
	lng: number;
	zoom: number;
	northwest: { lat: number; lng: number } | null;
	southeast: { lat: number; lng: number } | null;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'RegionMap',
  props: {
    destinationName: {},
    staticMaps: {},
    customMarkerData: { default: () => [] },
    lat: {},
    lng: {},
    zoom: {},
    northwest: {},
    southeast: {}
  },
  setup(__props: any) {

const hotelRequestBody = ref<ApiDataTypes>();

const store = useStore();

// Create a computed property for onlyHotel to ensure reactivity
const isHotelOnly = computed(() => store.state.searchMask.onlyHotel);

const apiData = (override?: any, exclude?: string[]): ApiDataTypes => store.getters['searchMask/apiData'](override, exclude);

function updateHotelRequestBody() {
	const exclude = isHotelOnly.value ? ['DepartureAirport', 'DirectFlight', 'Transfer'] : [];
	exclude.push('DefiniteStartDate', 'TourOperatorCodes', 'HotelIffCode');
	const travelType = isHotelOnly.value ? 'Accommodation' : 'Package';
	hotelRequestBody.value = apiData({ travelType }, exclude);
}

watch(
	() => store.state.searchMask.onlyHotel,
	() => {
		updateHotelRequestBody();
	},
	{ immediate: true }
);

onMounted(() => {
	updateHotelRequestBody();
});



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("h3", _hoisted_2, _toDisplayString(_ctx.destinationName) + " Karte", 1 /* TEXT */),
    (hotelRequestBody.value)
      ? (_openBlock(), _createBlock(MapPreviewAndModal, {
          key: 0,
          lat: _ctx.lat,
          lng: _ctx.lng,
          title: _ctx.destinationName,
          "maps-key": 'AIzaSyDBc2xkj5NW4H9Llr0x4XsQ6zPkO5hDa7I',
          "static-maps": _ctx.staticMaps,
          marker: false,
          zoom: _ctx.zoom,
          "custom-marker-data": _ctx.customMarkerData,
          "ga-event-label": 'Urlaubsseite',
          northwest: _ctx.northwest,
          southeast: _ctx.southeast,
          "hotel-request-body": hotelRequestBody.value!,
          "open-fullscreen": "true",
          class: "region-map__map"
        }, null, 8 /* PROPS */, ["lat", "lng", "title", "static-maps", "zoom", "custom-marker-data", "northwest", "southeast", "hotel-request-body"]))
      : _createCommentVNode("v-if", true)
  ]))
}
}

})