import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "shop-list" }
const _hoisted_2 = ["id"]
const _hoisted_3 = { key: 0 }
const _hoisted_4 = ["href"]
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { class: "shop-list__card-container" }

import { onBeforeMount, ref } from 'vue';
import type { ShopInfo as ShopList } from '@/interfaces/shop-info';
import ShopCard from '../ShopCard/ShopCard.vue';
import InternalNavigationBar from '@lmt-rpb/Molecules/InternalNavigationBar/InternalNavigationBar.vue';
import InternalNavigationLink from '@lmt-rpb/Molecules/InternalNavigationBar/internals/InternalNavigationLink.vue';

interface Props {
	shopList: ShopList[];
}


export default /*@__PURE__*/_defineComponent({
  __name: 'ShopList',
  props: {
    shopList: {}
  },
  setup(__props: any) {

const props = __props;

const currentLocation = document.location.protocol + '//' + document.location.host + document.location.pathname;
const sections = ref<HTMLElement[] | null>(null);

const sectionsMap: {
	name: string;
	nameOriginal: string;
	city: string | undefined;
	numberOfShopsInCity: number;
	link: string;
}[] = [];

const initMenu = () => {
	const statesSet = Array.from(
		props.shopList.filter((entry, index) => props.shopList.map((s) => s.state).indexOf(entry.state) === index)
	);
	statesSet.forEach((s) => {
		const shops = props.shopList.filter((shopinfo) => shopinfo.city === s.city).length;
		sectionsMap.push({
			name: s.state_link,
			nameOriginal: s.state,
			city: s.city,
			link: s.shop_list_link,
			numberOfShopsInCity: shops,
		});
	});
};

onBeforeMount(() => {
	initMenu();
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (sectionsMap.length > 1)
      ? (_openBlock(), _createBlock(InternalNavigationBar, {
          key: 0,
          "center-alignment": "",
          class: "shop-list__links-container"
        }, {
          default: _withCtx(() => [
            (_openBlock(), _createElementBlock(_Fragment, null, _renderList(sectionsMap, (s) => {
              return _createVNode(InternalNavigationLink, {
                key: s.link,
                label: s.nameOriginal,
                href: currentLocation + '#' + s.name,
                "anchor-id": s.name
              }, null, 8 /* PROPS */, ["label", "href", "anchor-id"])
            }), 64 /* STABLE_FRAGMENT */))
          ]),
          _: 1 /* STABLE */
        }))
      : _createCommentVNode("v-if", true),
    (_openBlock(), _createElementBlock(_Fragment, null, _renderList(sectionsMap, (s) => {
      return _createElementVNode("div", {
        id: s.name,
        key: s.name,
        ref_for: true,
        ref_key: "sections",
        ref: sections,
        class: "shop-list__state-container"
      }, [
        _createElementVNode("h2", null, _toDisplayString(s.nameOriginal), 1 /* TEXT */),
        (s.numberOfShopsInCity > 1)
          ? (_openBlock(), _createElementBlock("h3", _hoisted_3, [
              (s.link)
                ? (_openBlock(), _createElementBlock("a", {
                    key: 0,
                    href: s.link
                  }, "Reisebüros in " + _toDisplayString(s.city), 9 /* TEXT, PROPS */, _hoisted_4))
                : (_openBlock(), _createElementBlock("span", _hoisted_5, "Reisebüros in " + _toDisplayString(s.city), 1 /* TEXT */))
            ]))
          : _createCommentVNode("v-if", true),
        _createElementVNode("div", _hoisted_6, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.shopList.filter((shopinfo) => shopinfo.state === s.nameOriginal), (item, index) => {
            return (_openBlock(), _createBlock(ShopCard, {
              key: index,
              "shop-list": item,
              class: "shop-list__card"
            }, null, 8 /* PROPS */, ["shop-list"]))
          }), 128 /* KEYED_FRAGMENT */))
        ])
      ], 8 /* PROPS */, _hoisted_2)
    }), 64 /* STABLE_FRAGMENT */))
  ]))
}
}

})