import RegionGallery from '@lmt-rpb/RegionGallery/RegionGallery.vue';
import { createApp } from 'vue';

export const initRegionGallery = () => {
	const mediaMosaic = document.querySelector('.rpb_container.media-mosaic');
	const mountPoint = document.createElement('div');
	mediaMosaic?.appendChild(mountPoint);
	const gallery = createApp(RegionGallery);
	gallery.mount(mountPoint);
};
