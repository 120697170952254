<template>
	<section class="region-map">
		<h3 class="region-map__headline">{{ destinationName }} Karte</h3>
		<MapPreviewAndModal
			v-if="hotelRequestBody"
			:lat="lat"
			:lng="lng"
			:title="destinationName"
			:maps-key="'AIzaSyDBc2xkj5NW4H9Llr0x4XsQ6zPkO5hDa7I'"
			:static-maps="staticMaps"
			:marker="false"
			:zoom="zoom"
			:custom-marker-data="customMarkerData"
			:ga-event-label="'Urlaubsseite'"
			:northwest="northwest"
			:southeast="southeast"
			:hotel-request-body="hotelRequestBody!"
			open-fullscreen="true"
			class="region-map__map"
		/>
	</section>
</template>

<script lang="ts" setup>
import MapPreviewAndModal from '@lmt-rpb/MapPreviewAndModal/MapPreviewAndModal.vue';
import type { StaticMaps, MarkerData } from '@/interfaces/google-maps';
import { onMounted, ref, computed, watch } from 'vue';
import { useStore } from '@/components/common/store';
import type { ApiDataTypes } from '@/components/common/types';

interface Props {
	destinationName: string;
	staticMaps: StaticMaps;
	customMarkerData?: Array<MarkerData>;
	lat: number;
	lng: number;
	zoom: number;
	northwest: { lat: number; lng: number } | null;
	southeast: { lat: number; lng: number } | null;
}

const hotelRequestBody = ref<ApiDataTypes>();

const store = useStore();

// Create a computed property for onlyHotel to ensure reactivity
const isHotelOnly = computed(() => store.state.searchMask.onlyHotel);

const apiData = (override?: any, exclude?: string[]): ApiDataTypes => store.getters['searchMask/apiData'](override, exclude);

function updateHotelRequestBody() {
	const exclude = isHotelOnly.value ? ['DepartureAirport', 'DirectFlight', 'Transfer'] : [];
	exclude.push('DefiniteStartDate', 'TourOperatorCodes', 'HotelIffCode');
	const travelType = isHotelOnly.value ? 'Accommodation' : 'Package';
	hotelRequestBody.value = apiData({ travelType }, exclude);
}

watch(
	() => store.state.searchMask.onlyHotel,
	() => {
		updateHotelRequestBody();
	},
	{ immediate: true }
);

onMounted(() => {
	updateHotelRequestBody();
});

withDefaults(defineProps<Props>(), {
	customMarkerData: () => [],
});
</script>

<style lang="scss" scoped>
.region-map {
	width: 46rem;
	min-width: 25rem;

	&__headline {
		text-align: left;
		font-size: $font-medium-1;
		font-weight: $font-weight-semibold;
		padding: 2rem 2rem 1rem;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}

	&__map {
		padding: 0;
		height: 71%;
	}
}
</style>
