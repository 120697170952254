import { useStore } from '@/components/common/store';
import { EventBus } from '@global-js/event-bus';
import { parseSearchMaskAndFilterSettings, parsedDataToQuery } from '@components/common/services/transform';
import { documentReady } from '@/js/global/helper';
import { throttle, scrollIt } from '../utils/utils';
import { getBaseUrl } from '@utils/environmentUtils';
import { initRegionGallery } from '../modules/regionGallery';

const store = useStore();

const quickpillsId = document.getElementById('all-regions') || document.getElementById('all-cities');
const moreRegionsLinkNodeList = document.querySelectorAll('.show-more');

/**
 * Creates a fixed position button that links to the ibe pages.
 *
 * @return {void}
 */
function initFixedButton() {
	const dataSpan = document.querySelector('#search-mask-data, #region-seasons');
	if (!dataSpan) return;
	const data = dataSpan.dataset;
	const autoSuggestDestinationId = store.state.searchMask.destination.id;
	if (!data || !data.destinationType || !data.destinationName || !autoSuggestDestinationId) return;

	const buttonWrapper = document.createElement('div');
	const baseUrl = getBaseUrl();
	const pageTypes = {
		country: 'g',
		region_group: 'g',
		region: 'r',
		city: 'o',
	};
	let params = '';

	if (document.body.classList.contains('page_topic')) {
		params = '';

		const urlParamsData = document.querySelector('.url-params-data')?.dataset;
		const mainSearchData = document.getElementById('search-mask-data')?.dataset;
		if (urlParamsData && mainSearchData) {
			const parsedMainSearchData = parseSearchMaskAndFilterSettings(mainSearchData);
			const parsedUrlParams = parseSearchMaskAndFilterSettings(urlParamsData).urlParams;
			const parsedParams = parsedDataToQuery({ ...parsedMainSearchData, ...parsedUrlParams });
			if (parsedParams) params = `?${parsedParams}`;
		}
	}

	const url = `${baseUrl}/hotels/${pageTypes[data.destinationType]}/${autoSuggestDestinationId}/${params}`;

	buttonWrapper.className = 'rpb_destination__fixed-button-wrapper rpb_container';
	buttonWrapper.innerHTML = `
		<a
			href='${url}'
			title='${data.destinationName} Hotels'
			class='rpb_destination__fixed-button fade'
			data-ga-event='select_content'
			data-ga-content_type='FloatingButton'
			data-ga-item_id='HotelList'
		>
			Zu den Angeboten
		</a>`;

	const main = document.querySelector('.rpb_content');
	main.appendChild(buttonWrapper);

	const button = document.querySelector('.rpb_destination__fixed-button');
	let below = false;

	let passiveSupported = false;
	try {
		const opts = Object.defineProperty({}, 'passive', {
			get() {
				passiveSupported = true;
				return true;
			}
		});
		window.addEventListener('testPassive', null, opts);
		window.removeEventListener('testPassive', null, opts);
	} catch (err) {
		passiveSupported = false;
		console.error(err);
	}

	document.addEventListener('scroll', throttle(() => {
		const yPosition = window.pageYOffset;

		if (yPosition > 400 && !below) {
			below = true;
			button.classList.remove('fade');
		} else if (yPosition < 400 && below) {
			below = false;
			button.classList.add('fade');
		}
	}, 150), passiveSupported ? { passive: true } : false);
}

/**
 * Scroll to and open Quickpills
 * @param {*} event The click event
 */
function scrollToAndOpenQuickpills(event) {
	if (!event) return;

	event.preventDefault();
	event.stopPropagation();

	scrollIt(quickpillsId, 600, 'linear', 0);
	EventBus.$emit('Destination:OpenQuickPills');
}

/**
 * Hide show more regions buttons
 */
function hideMoreLinks() {
	const firstOccuranceOfQuickpills = document.querySelector('.quickpill-list .quickpill-list__pills');
	if (!firstOccuranceOfQuickpills) return;
	const firstOccuranceOfQuickpillsAnchorNodeList = firstOccuranceOfQuickpills.querySelectorAll('.quickpill a');

	if (firstOccuranceOfQuickpillsAnchorNodeList && firstOccuranceOfQuickpillsAnchorNodeList.length <= 5 && moreRegionsLinkNodeList?.length) {
		moreRegionsLinkNodeList.forEach((moreLink) => {
			// eslint-disable-next-line no-param-reassign
			moreLink.classList.add('hide');
		});
	}
}

/**
 * Set event listeners for more regions buttons
 */
function setMoreRegionsLinkEventListeners() {
	if (quickpillsId && moreRegionsLinkNodeList?.length) {
		moreRegionsLinkNodeList.forEach((moreRegionsLinkElement) => {
			moreRegionsLinkElement.addEventListener('click', scrollToAndOpenQuickpills);
		});
	}
}

function addSearchEmitToShowMoreButton() {
	const buttons = document.querySelectorAll('.show-more-regions');
	buttons.forEach((button) => button.addEventListener('click', () => EventBus.$emit('search:submit', true)), { once: true });
}

function addScrollEventToShowMoreCityButton() {
	const buttons = document.querySelectorAll('.show-more-cities');
	buttons.forEach((button) => button.addEventListener('click', scrollToAndOpenQuickpills));
}

EventBus.$once('Autocomplete:updatedActiveItem', initFixedButton);

const bodyClassList = document.body.classList;

if (bodyClassList.contains('page_country') || bodyClassList.contains('page_region')) {
	initRegionGallery();
	setMoreRegionsLinkEventListeners();
	documentReady(hideMoreLinks);
	addSearchEmitToShowMoreButton();
	addScrollEventToShowMoreCityButton();
}