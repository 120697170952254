<template>
	<div class="shop-list">
		<InternalNavigationBar
			v-if="sectionsMap.length > 1"
			center-alignment
			class="shop-list__links-container"
		>
			<InternalNavigationLink
				v-for="s in sectionsMap"
				:key="s.link"
				:label="s.nameOriginal"
				:href="currentLocation + '#' + s.name"
				:anchor-id="s.name"
			/>
		</InternalNavigationBar>
		<div
			v-for="s in sectionsMap"
			:id="s.name"
			:key="s.name"
			ref="sections"
			class="shop-list__state-container"
		>
			<h2>{{ s.nameOriginal }}</h2>
			<h3 v-if="s.numberOfShopsInCity > 1">
				<a
					v-if="s.link"
					:href="s.link"
				>Reisebüros in {{ s.city }}
				</a>
				<span v-else>Reisebüros in {{ s.city }}</span>
			</h3>
			<div class="shop-list__card-container">
				<ShopCard
					v-for="(item, index) in shopList.filter((shopinfo) => shopinfo.state === s.nameOriginal)"
					:key="index"
					:shop-list="item"
					class="shop-list__card"
				/>
			</div>
		</div>
	</div>
</template>

<script lang="ts" setup>
import { onBeforeMount, ref } from 'vue';
import type { ShopInfo as ShopList } from '@/interfaces/shop-info';
import ShopCard from '../ShopCard/ShopCard.vue';
import InternalNavigationBar from '@lmt-rpb/Molecules/InternalNavigationBar/InternalNavigationBar.vue';
import InternalNavigationLink from '@lmt-rpb/Molecules/InternalNavigationBar/internals/InternalNavigationLink.vue';

interface Props {
	shopList: ShopList[];
}

const props = defineProps<Props>();

const currentLocation = document.location.protocol + '//' + document.location.host + document.location.pathname;
const sections = ref<HTMLElement[] | null>(null);

const sectionsMap: {
	name: string;
	nameOriginal: string;
	city: string | undefined;
	numberOfShopsInCity: number;
	link: string;
}[] = [];

const initMenu = () => {
	const statesSet = Array.from(
		props.shopList.filter((entry, index) => props.shopList.map((s) => s.state).indexOf(entry.state) === index)
	);
	statesSet.forEach((s) => {
		const shops = props.shopList.filter((shopinfo) => shopinfo.city === s.city).length;
		sectionsMap.push({
			name: s.state_link,
			nameOriginal: s.state,
			city: s.city,
			link: s.shop_list_link,
			numberOfShopsInCity: shops,
		});
	});
};

onBeforeMount(() => {
	initMenu();
});
</script>

<style lang="scss" scoped>
.shop-list {
	min-height: 1vh;

	h2 {
		font-size: $font-large-2;
		font-weight: $font-weight-semibold;
		text-align: left;
		padding: 3.4rem 0 2.4rem;
		font-family: $font-family-special;
	}

	h3 {
		font-size: $font-large-4;
		font-weight: $font-weight-semibold;
		text-align: left;
		padding: 0 0 1.6rem;
		font-family: $font-family-special;
	}

	&__links-container {
		position: sticky;
		top: 6rem;
		justify-content: center;
		transition: all 0.15s ease-out;
	}

	&__state-container {
		max-width: 144rem;
		margin: auto;
		scroll-margin: 10rem;
	}

	.page-header--hidden &__links-container {
		top: 0;
		position: sticky;
		transition: all 0.25s ease-out;
	}

	&__card-container {
		display: flex;
		flex-direction: column;
		gap: 2.4rem;
	}

	@media (min-width: $breakpoint-small) {
		&__links-container {
			top: 8.8rem;
		}
	}

	@media (min-width: $breakpoint-medium) {
		h1 {
			font-size: $font-large-1;
		}
	}

	@media (min-width: $breakpoint-large) {
		h2 {
			margin: 0 3.6rem;
		}

		h3 {
			padding: 0 3.6rem 1.6rem;
		}

		&__card-container {
			display: flex;
			flex-direction: column;
			gap: 2.4rem;
			margin: 0 3.6rem;
		}
	}
}
</style>
