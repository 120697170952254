/* eslint-disable camelcase */
import { createApp } from 'vue';
import Quickinfos from '@lmt-rpb/Quickinfos/Quickinfos.vue';
import { ClimateData, DestinationFacts } from '@/interfaces/quickinfo-types';
import { StaticMaps } from '@/interfaces/google-maps';
import { mountReplace } from '../utils/mountUtils';

interface QuickInfosData {
	static_map_urls: StaticMaps,
	lat: number;
	lng: number;
	northwest: {lat: number, lng: number} | null;
	southeast: {lat: number, lng: number} | null;
	map_zoom: number;
	climate: ClimateData;
	time_to_visit: string;
	climate_region?: string;
	destination_name: string;
	facts: string;
	introtext: string;
}

const selector = 'rpb_quickinfo-mountpoint';
const mountPoint: HTMLElement | null = document.getElementById(selector);
const dataSet: DOMStringMap | undefined = mountPoint?.dataset;
const quickInfosData: QuickInfosData | Record<string, never> = dataSet?.quickInfos ? JSON.parse(dataSet.quickInfos) : {};
const customMarkerDataElements: NodeList = document.querySelectorAll('.rpb_quickpills__data > .data-quickpill');
const customMarkerData: Array<DOMStringMap> = [];

if (customMarkerDataElements) {
	customMarkerDataElements.forEach((currentValue) => {
		if ((currentValue as HTMLElement).dataset) {
			customMarkerData.push((currentValue as HTMLElement).dataset);
		}
	});
}

const initQuickinfos = () => {
	const destinationData: DestinationFacts = {
		destinationName: quickInfosData.destination_name,
		facts: quickInfosData.facts,
		introtext: quickInfosData.introtext,
		bestTimeToVisit: quickInfosData.time_to_visit
	};
	const app = createApp(Quickinfos, {
		destinationData,
		climateData: quickInfosData.climate,
		climateRegion: quickInfosData?.climate_region || '',
		staticMaps: quickInfosData.static_map_urls,
		northwest: quickInfosData.northwest,
		southeast: quickInfosData.southeast,
		lat: quickInfosData.lat,
		lng: quickInfosData.lng,
		zoom: quickInfosData.map_zoom,
		customMarkerData
	});
	mountReplace(app, mountPoint!);
};

if (mountPoint && dataSet && quickInfosData && Object.keys(quickInfosData).length > 0) {
	initQuickinfos();
} else {
	// Remove mountpoint if unused
	mountPoint?.remove();
}
