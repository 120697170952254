<template>
	<a
		class="shop-card"
		:href="shopList.uri_detail"
	>
		<h4> {{ shopList.name }} </h4>
		<div class="shop-card__content-container">
			<div class="shop-card__image-container">
				<img
					v-if="imgPath"
					:src="imgPath"
					class="shop-card__image"
				/>
				<div v-else>
				</div>
			</div>
			<ShopContactData
				:shop-info="shopList"
				class="shop-card__contact-data"
			/>
			<div class="shop-card__further-info" v-html="shopList.introtext">
			</div>
		</div>
		<RoundedButton
			theme="extra"
			class="shop-card__button"
			:title="shopList.name"
		>
			Reisebüroinfo
		</RoundedButton>
	</a>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { getBaseUrl } from '@utils/environmentUtils';
import { ShopCard } from '@/interfaces/shop-info';
import RoundedButton from '../RoundedButton/RoundedButton.vue';
import ShopContactData from '../ShopContactData/ShopContactData.vue';

interface Props {
	shopList: ShopCard;
}

const props = defineProps<Props>();

const imgPath = computed((): string => (props.shopList.image ? getBaseUrl() + props.shopList.image : ''));

</script>

<style lang="scss" scoped>
.shop-card {
	max-width: 145rem;
	padding: 0.1rem 1.6rem;
	border-radius: $border-radius-small;
	box-shadow: $box-shadow;
	font-size: $font-small-2;
	fill: $color-link;
	color: $color-black;

	&:hover {
		text-decoration: none;
	}

	&__image-container {
		display: none;
	}

	&__content-container {
		display: flex;
		align-items: flex-start;
		flex-direction: column;
		gap: 1.4rem;
	}

	h4 {
		margin: 2.4rem 0;
		padding: 0;
		font-size: $font-medium-3;
		font-weight: $font-weight-semibold;
		color: $color-link;

		&:hover {
			text-decoration: underline;
		}
	}

	&__further-info {
		flex: 1;
	}

	&__public-transport {
		font-weight: $font-weight-bold;
	}

	&__parking {
		font-weight: $font-weight-bold;
		margin-top: 1.6rem;
	}

	&__payment-options {
		margin-top: 1.6rem;
	}

	&__payment-options-title {
		font-weight: $font-weight-semibold;
		margin-bottom: 0.4rem;
	}

	&__button {
		display: block;
		background: $color-extra-light;
		padding: 0.8rem 2.4rem;
		margin: 2.4rem auto;

		&:hover {
			text-decoration: none;
		}
	}

	@media (min-width: $breakpoint-small) {
		font-size: $font-small-1;
		padding: 0.1rem 3.2rem;

		h4 {
			font-size: $font-medium-1;
		}

		&__content-container {
			flex-direction: row;
			gap: 2rem;
		}

		&__button {
			margin: 1.4rem 0 2.4rem auto;
		}
	}

	@media (min-width: $breakpoint-large) {
		padding: 0.1rem 5rem;

		&__further-info {
			column-count: 1;
		}

		&__payment-options {
			margin-top: 2rem;
			grid-column: auto / auto;
			grid-row: auto / auto;
		}

		&__payment-options-content {
			line-height: 1;
		}

		&__content-container {
			flex-direction: row;
			gap: 4.8rem;
		}

		&__image-container {
			display: block;
		}

		&__image {
			width: 32.3rem;
		}

		:deep(.shop-card-contact-data) {
			flex: 1;
			grid-auto-flow: column;
			grid-template-rows: auto auto auto auto;
			grid-template-columns: auto;

			.shop-card-contact-data__address,
			.shop-card-contact-data__email {
				margin-right: 0;
				max-width: 32rem;
			}
		}
	}
}
</style>
