import { createApp } from 'vue';
import ShopList from '@lmt-rpb/ShopDetails/ShopDetails.vue';
import { ShopInfo } from '@/interfaces/shop-info';
import { mountReplace } from '../utils/mountUtils';

const shopInfoElement = document.querySelector('.rpb__shop-detail') as HTMLElement;

const addStaticMaps = (shopInfo: any) => {
	// eslint-disable-next-line no-param-reassign
	shopInfo.staticMaps = {
		staticgmap_420: shopInfo.staticgmap_mobile,
	};
};

const initShopList = (shopInfo: ShopInfo) => {
	const app = createApp(ShopList, {
		shopInfo,
	});

	mountReplace(app, shopInfoElement);
};

if (shopInfoElement?.dataset?.storeRecords) {
	const shopInfoParsed = JSON.parse(shopInfoElement.dataset.storeRecords);
	addStaticMaps(shopInfoParsed);
	initShopList(shopInfoParsed);
}
