<template>
	<GalleryModal
		v-model="galleryOpen"
		:elements="galleryItems"
		:index="galleryIndex"
		@close="closeGallery"
	/>
</template>

<script setup lang="ts">

import GalleryModal from '@lmt-rpb/GalleryModal/GalleryModal.vue';
import { onMounted, onUnmounted, ref } from 'vue';
import { useGalleryListeners } from '../../composables/galleryListeners';
import type { GalleryElement } from '@/interfaces/components/galleryElement';

const galleryOpen = ref(false);
const galleryItems = ref<Array<GalleryElement>>([]);
const galleryIndex = ref(0);

function initLightboxImages() {
	const elements: NodeListOf<HTMLLIElement> = document.querySelectorAll('[data-lightbox-content]');

	return Array.from(elements).map((element) => ({
			content: element.dataset.lightboxContent,
			preview: element.dataset.lightboxPreview ?? element.dataset.lightboxContent,
			copyright: element.dataset.lightboxCopyright ?? '',
			type: element.dataset.lightboxType ?? 'image',
		}));
}

function initAdditionalImages() {
	const mosaicData: HTMLElement | null = document.querySelector('.media-mosaic-data');
	if (!mosaicData?.dataset.mosaic) {
		mosaicData?.remove();
		return [];
	}
	const elements: {
		alt_text:string
		copyright?: string
		medium?: string
		original: string
		small: string
		title: string
	}[] = JSON.parse(mosaicData.dataset.mosaic);
	mosaicData?.remove();

	return Array.from(elements).map((element) => ({
			content: element.original,
			preview: element.small ?? element.original,
			copyright: element.copyright ?? '',
			type: 'image'
		}));
}

function initLightboxVideo() {
	const elements: NodeListOf<HTMLLIElement> = document.querySelectorAll('[data-lightbox-video-content]');

	return Array.from(elements).map((element) => ({
			content: element.dataset.lightboxVideoContent,
			preview: element.dataset.lightboxVideoPreview ?? element.dataset.lightboxVideoContent,
			copyright: element.dataset.lightboxVideoCopyright ?? '',
			type: element.dataset.lightboxVideoType ?? 'image',
		}));
}

function initGalleryImages() {
	galleryItems.value = [
		...initLightboxImages(),
		...initAdditionalImages(),
		...initLightboxVideo(),
	] as GalleryElement[];
}

const handleButtonClick = () => {
	galleryOpen.value = !galleryOpen.value;
};

const openGallery = (index: number) => {
	galleryIndex.value = index;
	galleryOpen.value = true;
};

const closeGallery = () => {
	galleryOpen.value = false;
};

useGalleryListeners('.media-mosaic__image', openGallery);

onMounted(() => {
	initGalleryImages();
	document.querySelector('.media-mosaic__button')?.addEventListener('click', handleButtonClick);
});

onUnmounted(() => {
	document.querySelector('.media-mosaic__button')?.removeEventListener('click', handleButtonClick);
});

</script>
